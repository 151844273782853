div.App {
    text-align: center;
    padding: 2em;
}

div.error-page {
    text-align: center;
}

div.error-page > img {
    margin-top: 4em;
    max-width: 45em;
}

form {
    width: 100%;
}
